<template>
  <div class="card-booking">
    <div class="card-booking-header">
      <OrderStatus :status="statusCode | orderStatus" />
      <div class="card-booking-ref is-size-5">
        {{ item.order.orderReference }}
      </div>
      <div class="card-booking-amount">
        <span>Commission:</span><strong class="is-size-5 has-text-primary m-r-4x">{{ item.order.agentProfit | currencyObj }}</strong>
        <span>{{ $t('views.dashboard.booking-amount') }}:</span><strong class="is-size-5 has-text-primary">{{ item.order.agentActualChargeAmount.value | currency({currency: item.order.agentActualChargeAmount.currency}) }}</strong>
      </div>
    </div>
    <div class="card-booking-content columns-7">
      <div class="attr attr-hotel">
        <div class="attr-key">
          {{ $t('views.dashboard.hotel') }}
        </div>
        <div class="attr-val">
          <div class="has-text-primary has-text-weight-bold">
            {{ item.property.name }}
          </div>
          <div class="is-size-7">
            {{ `${item.property.address}, ${item.property.city}` }}
          </div>
          <div
            v-if="$isFoodCodeValid(item.booking.foodCode)"
            class="m-t is-size-7">
            {{ item.booking.foodCode | foodCode }}
          </div>
        </div>
      </div>
      <div class="attr attr-date">
        <div class="attr-key">
          {{ $t('views.dashboard.travel-date') }}
        </div>
        <div class="attr-val">
          {{ item.booking.checkInDate | date('MMM DD, YYYY') }} - {{ item.booking.checkOutDate | date('MMM DD, YYYY') }}
        </div>
      </div>
      <div
        v-if="mode === 'full'"
        class="attr attr-booking-date">
        <div class="attr-key">
          {{ $t('views.MyBookings.booking-date') }}
        </div>
        <div class="attr-val">
          {{ item.booking.createdAt | date('MMM DD, YYYY HH:mm') }}
        </div>
      </div>
      <div class="attr attr-name">
        <div class="attr-key">
          {{ $t('views.dashboard.guest-name') }}
        </div>
        <div class="attr-val">
          {{ item.contactPerson.fullName || 'N/A' }}
        </div>
      </div>
      <div
        v-if="item.status==='ON_HOLD' && mode === 'full'"
        class="attr attr-name">
        <div class="attr-key">
          {{ $t('Pay by') }}
        </div>
        <div class="attr-val">
          {{ item.booking.freeCancellationBefore | dateTz({ tz: 'Africa/Johannesburg', format: 'MMM DD, YYYY, HH:mm', isBeforeMidnight: true }) }}
          <div>
            <router-link
              to="/"
              class="has-text-secondary">
              {{ $t('Make payment') }}
            </router-link>
          </div>
        </div>
      </div>
      <div
        v-if="item.status!=='ON_HOLD' && mode === 'full'"
        class="attr attr-name">
        <div class="attr-key">
          {{ $t('views.MyBookings.Latest free cancellation') }}
        </div>
        <div class="attr-val">
          {{ item.booking.freeCancellationBefore | dateTz({ tz: 'Africa/Johannesburg', format: 'MMM DD, YYYY, HH:mm', isBeforeMidnight: true }) }}
        </div>
      </div>
      <div
        v-if="mode === 'full'"
        class="attr attr-user">
        <div class="attr-key">
          {{ $t('views.MyBookings.User') }}
        </div>
        <div class="attr-val">
          {{ item.order.createdBy }}
        </div>
      </div>
      <div class="attr attr-action">
        <div class="attr-key">
&nbsp;
        </div>
        <div class="attr-val">
          <router-link :to="{ name: 'my-bookings-detail', params: {orderReference: item.order.orderReference} }">
            <el-button class="button-secondary">
              {{ $t('views.dashboard.details') }}
            </el-button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OrderStatus from '@/components/OrderStatus'

export default {
  name: 'MyBookingsCard',
  components: {
    OrderStatus
  },
  props: {
    item: {
      type: Object,
      default: () => {},
      required: true
    },
    mode: {
      type: String,
      default: 'full'
    }
  },
  computed: {
    statusCode () {
      return this.item.order && this.item.order.status && this.item.order.status.code
    }
  },
  i18n: {
    messages: {
      'en-US': {
        'Pay by': 'Pay by',
        'Make payment': 'Make payment'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .card-header {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
  }

  .attr-hotel {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .has-text-ellipsis {
    .attr {
      white-space: normal;
    }
  }
</style>
