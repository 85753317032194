<template>
  <div class="dashboard-page">
    <div class="hero has-background-primary">
      <div class="container is-desktop">
        <SearchForm @search="$router.push('search')" />
      </div>
    </div>

    <div class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-3">
            <!-- account summary start -->
            <div class="heading">
              <h3 class="title is-size-4 has-text-secondary m-b-2x">
                {{ $t('views.dashboard.account-summary') }}
              </h3>
            </div>
            <div class="columns is-multiline">
              <div class="column is-12">
                <div class="account-info m-b-2x">
                  <span class="has-text-primary has-text-weight-bold m-r-2x">0</span><span>{{ $t('views.dashboard.check-in-today') }}</span>
                </div>
                <div class="account-info m-b-2x">
                  <span class="has-text-primary has-text-weight-bold  m-r-2x">0</span><span>{{ $t('views.dashboard.check-in-tomorrow') }}</span>
                </div>
                <div class="account-info m-b-2x">
                  <span class="has-text-primary has-text-weight-bold  m-r-2x">0</span><span>{{ $t('views.dashboard.refundable-deadlines-today') }}</span>
                </div>
              </div>
            </div>
            <!-- account summary end -->
            <div class="heading">
              <h3 class="title is-size-4 has-text-secondary">
                {{ $t('views.dashboard.bookmarks') }}
              </h3>
            </div>
            <div class="columns is-multiline">
              <div class="column is-12">
                <EmptyState :message="$t('views.dashboard.you-have-no-bookmark')" />
              </div>
            </div>
          </div>
          <div class="column is-9">
            <div class="heading">
              <h3 class="title is-size-4 has-text-secondary">
                {{ $t('views.dashboard.last-bookings') }}
              </h3>
            </div>
            <div class="columns is-multiline">
              <div class="column is-12">
                <EmptyState :message="$t('views.dashboard.you-have-no-booking')" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchForm from '../share/SearchForm'

export default {
  name: 'Dashboard',
  components: {
    SearchForm
  }
}
</script>

<style scoped>

</style>
