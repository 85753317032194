<template>
  <div class="dashboard-page">
    <div class="hero has-background-primary">
      <div class="container is-fullhd">
        <SearchForm @search="handleSearch" />
      </div>
    </div>
    <div
      v-if="recentViews.length"
      class="section">
      <div class="container">
        <div class="heading">
          <h3 class="title is-size-4 has-text-black">
            {{ $t('views.dashboard.recent-views') }}
          </h3>
          <a
            href="#"
            class="heading-link is-size-7"
            @click.prevent="clearRecentViews">
            {{ $t('views.dashboard.clear-history') }}
          </a>
        </div>
        <div
          v-loading="isFetching.recentViews"
          class="columns">
          <div
            v-for="(recentView, idx) in recentViews"
            :key="idx"
            class="column is-3 has-text-ellipsis">
            <div
              class="card-property"
              @click="recentViewClick(recentView.url)">
              <i
                v-lazy:backgroundImage="{src:recentView.heroImage.url,error:imageErrorPlaceholder}"
                class="thumb" />
              <h3 class="has-text-primary has-text-weight-bold has-text-ellipsis">
                {{ recentView.propertyName }}
              </h3>
              <div class="is-size-7">
                {{ recentView.checkInDate | date('MMM D') }}
                -
                {{ recentView.checkOutDate | date('MMM D') }}
              </div>
              <div class="is-size-5 has-text-right has-text-primary has-text-weight-bold m-t">
                {{ recentView.displayRate | currencyObj({ factionDigits: 0 }) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="userRole !== 'CLIENT_AGENT_S'"
      class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-3">
            <!-- account summary start -->
            <div class="heading">
              <h3 class="title is-size-4 has-text-black m-b-2x">
                {{ $t('views.dashboard.booking-summary') }}
              </h3>
            </div>
            <div class="columns is-multiline">
              <div class="column is-12">
                <div
                  class="account-info m-b-2x"
                  @click="toManageBookings('checkin')">
                  <span class="has-text-dark-blue has-text-weight-bold m-r-2x">{{ accountSummary.checkin }}</span><span>{{ $t('views.dashboard.check-in-next-7-days') }}</span>
                </div>
                <div
                  class="account-info m-b-2x"
                  @click="toManageBookings('payment')">
                  <span class="has-text-dark-blue has-text-weight-bold  m-r-2x">{{ accountSummary.payment }}</span><span>{{ $t('views.dashboard.payment-next-7-days') }}</span>
                </div>
                <div
                  class="account-info m-b-2x"
                  @click="toManageBookings('refundableDeadline')">
                  <span class="has-text-dark-blue has-text-weight-bold  m-r-2x">{{ accountSummary.refundableDeadline }}</span><span>{{ $t('views.dashboard.refundable-deadlines-next-7-days') }}</span>
                </div>
              </div>
            </div>
            <!-- account summary end -->
            <!-- property bookmarks start -->
            <div class="heading">
              <h3 class="title is-size-4 has-text-black">
                {{ $t('views.dashboard.bookmarks') }}
              </h3>
              <a
                :class="{'disabled': propertyBookmarks.totalItems <= 2}"
                href="#"
                class="heading-link is-size-7"
                @click="handleMoreBookmarks">
                {{ $t('views.dashboard.view-more') }}
              </a>
            </div>
            <div
              v-loading="isFetching.propertyBookmarks"
              class="columns is-multiline">
              <BookmarkList
                :items="propertyBookmarks.items"
                @clicked="propertyBookmarkClick"
                @toggled="removeBookmark" />
            </div>
          </div>
          <!-- property bookmarks end -->
          <!-- last bookings start -->
          <div class="column is-9">
            <div class="heading">
              <h3 class="title is-size-4 has-text-black">
                {{ $t('views.dashboard.last-bookings') }}
              </h3>
              <router-link
                :to="{ name: 'my-bookings' }"
                class="heading-link is-size-7">
                {{ $t('views.dashboard.view-more') }}
              </router-link>
            </div>
            <div
              v-loading="isFetching.lastBookings"
              class="columns is-multiline">
              <div
                v-if="lastBookings.length"
                class="column is-12 has-text-elipsis">
                <MyBookingsCard
                  v-for="item in lastBookings"
                  :key="item.order.orderReference"
                  class="m-b-5x"
                  mode="short"
                  :item="item" />
              </div>
              <div
                v-else
                class="column is-12">
                <EmptyState :message="$t('views.dashboard.you-have-no-booking')" />
              </div>
            </div>
          </div>
          <!-- last bookings end -->
        </div>
      </div>
    </div>

    <el-drawer
      :title="$t('views.dashboard.bookmarks')"
      :visible="moreBookmarks"
      direction="ltr"
      size="100"
      @close="moreBookmarksClose">
      <div class="drawer-content">
        <div class="columns is-multiline">
          <BookmarkList
            :items="propertyBookmarks.items"
            @toggled="removeBookmark"
            @clicked="propertyBookmarkClick" />
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import { isAfter, add } from '@/plugins/dates'
import { serialize, getUrlParams } from '@/plugins/util'
import { retrieveAgent, retrieveUser } from '@/service/auth'
import SearchForm from '../share/SearchForm'
import BookmarkList from '@/components/BookmarkList'
import MyBookingsCard from '@/components/MyBookingsCard'
import EmptyState from '@/components/EmptyState'
import store from '@/store'

const asyncData = async ({ params }) => {
  try {
    // to indicate superadmin
    params.superAdmin = false
    await store.dispatch('dashboard/fetchLastBookings', params)
  } catch (err) {
    if (err.meta) store.commit('meta/SET_META_ACTION', err.meta)
    store.commit('SET_GLOBAL_MODAL', {
      show: true,
      content: err.message || (err.error && err.error.message) || ''
    })
  }
}
export default {
  name: 'Dashboard',
  components: {
    BookmarkList,
    EmptyState,
    MyBookingsCard,
    SearchForm
  },
  data () {
    return {
      imageErrorPlaceholder: require('@/assets/image-error-placeholder.jpg')
    }
  },
  computed: {
    ...mapState('searchMany', [
      'queryParams'
    ]),
    ...mapState('dashboard', [
      'isFetching',
      'recentViews',
      'propertyBookmarks',
      'moreBookmarks',
      'lastBookings',
      'accountSummary'
    ]),
    userRole () {
      return retrieveUser('role')
    }
  },
  async created () {
    this.$store.dispatch('dashboard/fetchRecentViews')
    this.$store.dispatch('dashboard/fetchPropertyBookmarks')
    if (this.userRole !== 'CLIENT_AGENT_S') {
      // this.$store.dispatch('dashboard/fetchLastBookings')
      await this.getLastBookings({ page: 1 })
      this.$store.dispatch('dashboard/fetchAccountSummary')
    }
  },
  methods: {
    async getLastBookings (params) {
      if (!params.orderStatuses) {
        // for staging, 2 more status need to be add: AWAITING_CONFIRMATION, ON_HOLD
        params.orderStatuses = 'CONFIRMED,PROCESSING,CANCELLED,REFUND_IN_PROGRESS,REFUND_FAILURE,AWAITING_CONFIRMATION,ON_HOLD'
      }

      this.$store.commit('SHOW_FULLSCREEN_LOADER')
      await asyncData({
        params: {
          ...params,
          limit: this.pageSize
        }
      })
      this.$store.commit('HIDE_FULLSCREEN_LOADER')
    },
    recentViewClick (url) {
      const newUrl = new URL(url)
      const path = newUrl.pathname
      const searchParams = newUrl.search
      let finalUrl = `${window.location.origin}${path}${searchParams}`
      const params = getUrlParams(searchParams)
      if (isAfter(params.checkInDate, 'd') || isAfter(params.checkOutDate, 'd')) {
        const checkInDate = add(new Date(), 7, 'd').format('YYYY-MM-DD')
        const checkOutDate = add(new Date(), 8, 'd').format('YYYY-MM-DD')
        params.checkInDate = checkInDate
        params.checkOutDate = checkOutDate
        const paramsStr = Object.entries(params).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&')
        finalUrl = `${window.location.origin}${path}?${paramsStr}`
      }

      window.open(finalUrl, '_blank')
    },
    clearRecentViews (e) {
      e.preventDefault()
      this.$store.dispatch('dashboard/clearRecentViews')
    },
    handleSearch (val) {
      const { regionName, locationQuery, ...query } = this.queryParams
      this.$store.commit('searchMany/SET_QUERY_PARAMS', { ...query, ...val })
      this.$store.dispatch('searchMany/search', query)
      this.$router.push({ name: 'search', params: { locationQuery }, query })
    },
    propertyBookmarkClick (property) {
      const now = new Date()
      now.setDate(now.getDate() + 10)
      const later = new Date(now)
      later.setDate(later.getDate() + 1)
      const searchOneQuery = {
        checkInDate: now.toJSON().split('T')[0],
        checkOutDate: later.toJSON().split('T')[0],
        adultCount: 2,
        roomCount: 1,
        currency: retrieveAgent('defaultCurrency'),
        locale: this.$store.state.user.locale,
        nationality: retrieveAgent('defaultSourceMarket'),
        propertyCode: property.propertyCode,
        searchId: this.$newSearchId(),
        locationQuery: property.locationQuery,
        regionId: property.regionId
      }
      const url = `${window.location.origin}/property/${property.name}${serialize(searchOneQuery)}`
      window.open(url, '_blank')
    },
    removeBookmark (val) {
      const tmp = this.propertyBookmarks.items.filter(({ property }) => property !== val)
      this.$store.commit('dashboard/SET_DASHBOARD', { propertyBookmarks: { items: tmp, totalItems: tmp.length } })
      this.$store.dispatch('user/toggleBookmark', {
        flag: false,
        property: val
      })
      // this.$store.dispatch('dashboard/fetchPropertyBookmarks')
    },
    handleMoreBookmarks (e) {
      e.preventDefault()
      if (this.propertyBookmarks.totalItems > 2) {
        this.$store.commit('dashboard/SET_DASHBOARD', {
          moreBookmarks: true,
          paginateBookmarks: {
            page: 1,
            limit: 99
          }
        })
        this.$store.dispatch('dashboard/fetchPropertyBookmarks')
      }
    },
    moreBookmarksClose () {
      this.$store.commit('dashboard/SET_DASHBOARD', {
        moreBookmarks: false,
        paginateBookmarks: {
          page: 1,
          limit: 2
        },
        propertyBookmarks: {
          items: this.propertyBookmarks.items ? this.propertyBookmarks.items.slice(0, 2) : [],
          totalItems: this.propertyBookmarks.items.length
        }
      })
    },
    toManageBookings (type) {
      let query = {}
      switch (type) {
      case 'checkin':
        query = {
          dateRangeType: 'checkin-date',
          checkInDateRange: `${moment(new Date()).format('YYYY-MM-DD')}|${moment(new Date()).add(7, 'd').format('YYYY-MM-DD')}`
        }
        this.$router.push({ name: 'my-bookings', query })
        break
      case 'payment':
        query = {
          paymentDeadlineDateRange: `${moment(new Date()).format('YYYY-MM-DD')}|${moment(new Date()).add(7, 'd').format('YYYY-MM-DD')}`
        }
        this.$router.push({ name: 'settle-payment', query })
        break
      default:
        this.$router.push({ name: 'my-bookings' })
        break
      }
    }
  },
  metaInfo () {
    return {
      title: 'Dashboard'
    }
  }
}
</script>

<style scoped>
.account-info {
  cursor: pointer;
}
</style>
