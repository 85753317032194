<template>
  <div
    v-if="!items.length"
    class="column is-12">
    <EmptyState
      :message="$t('views.dashboard.you-have-no-bookmark')" />
  </div>
  <div
    v-else
    class="column is-12">
    <div
      v-for="{property} in items"
      :key="property.propertyCode"
      :title="property.name"
      class="m-b-3x has-text-ellipsis">
      <div
        class="card-property bookmark-item"
        @click="$emit('clicked', property)">
        <i
          v-lazy:backgroundImage="{src:property.heroImage.url,error:imageErrorPlaceholder}"
          class="thumb">
          <div class="icon-bookmark">
            <Bookmark
              :status="true"
              @toggled="$emit('toggled', property)" />
          </div>
        </i>
        <StarRatings
          :ratings="property.starRating"
          class="prop-star is-size-7 is-narrow" />
        <h3 class="has-text-primary has-text-weight-bold has-text-ellipsis">
          {{ property.name }}
        </h3>
        <div class="is-size-7">
          {{ property.location.city }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bookmark from './Bookmark'
import StarRatings from './StarRatings'

export default {
  name: 'BookmarkList',
  components: {
    Bookmark,
    StarRatings
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      imageErrorPlaceholder: require('@/assets/image-error-placeholder.jpg')
    }
  }
}
</script>
