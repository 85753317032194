<template>
  <div class="dashboard-page">
    <div class="hero has-background-primary">
      <div class="container is-desktop">
        <SearchForm @search="$router.push('search')" />
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="heading">
          <h3 class="title is-size-4 has-text-secondary">
            {{ $t('views.dashboard.recent-views') }}
          </h3>
          <a
            href="#"
            class="heading-link  is-size-7">{{ $t('views.dashboard.clear-history') }}</a>
        </div>
        <div class="columns">
          <div
            v-for="i in 4"
            :key="i"
            class="column is-3  has-text-ellipsis">
            <div class="card-property">
              <i
                class="thumb"
                :style="`background-image: url(${require('../../assets/property-80X80.jpg')})`" />
              <h3 class="has-text-primary has-text-weight-bold has-text-ellipsis">
                Carlton City Hotel Singapore some text
              </h3>
              <div class="is-size-7">
                {{ '2020-1-1'|date('MMM D') }} - {{ '2020-1-2'|date('MMM D') }}
              </div>
              <div class="is-size-5 has-text-right has-text-primary has-text-weight-bold m-t">
                SGD 100
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-3">
            <!-- account summary start -->
            <div class="heading">
              <h3 class="title is-size-4 has-text-secondary m-b-2x">
                {{ $t('views.dashboard.account-summary') }}
              </h3>
            </div>
            <div class="columns is-multiline">
              <div class="column is-12">
                <div class="account-info m-b-2x">
                  <span class="has-text-primary has-text-weight-bold m-r-2x">27</span><span>{{ $t('views.dashboard.check-in-today') }}</span>
                </div>
                <div class="account-info m-b-2x">
                  <span class="has-text-primary has-text-weight-bold  m-r-2x">13</span><span>{{ $t('views.dashboard.check-in-tomorrow') }}</span>
                </div>
                <div class="account-info m-b-2x">
                  <span class="has-text-primary has-text-weight-bold  m-r-2x">15</span><span>{{ $t('views.dashboard.refundable-deadlines-today') }}</span>
                </div>
              </div>
            </div>
            <!-- account summary end -->
            <div class="heading">
              <h3 class="title is-size-4 has-text-secondary">
                {{ $t('views.dashboard.bookmarks') }}
              </h3>
              <a
                class="heading-link is-size-7"
                @click.prevent="bookmarkDrawerActive=true">{{ $t('views.dashboard.view-more') }}</a>
            </div>
            <div class="columns is-multiline">
              <div
                v-for="i in 4"
                :key="i"
                class="column is-12  has-text-ellipsis">
                <div class="card-property">
                  <i
                    class="thumb"
                    :style="`background-image: url(${require('../../assets/property-80X80.jpg')})`">
                    <div class="icon-bookmark">
                      <Bookmark :status="true" />
                    </div>
                  </i>
                  <span class="has-text-yellow"><i
                    v-for="idx in 5"
                    :key="idx"
                    class="el-icon-star-on" /> </span>
                  <h3 class="has-text-primary has-text-weight-bold has-text-ellipsis">
                    Carlton City Hotel Singapore some text
                  </h3>
                  <div class="is-size-7">
                    Singapore, Singapore
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-9">
            <div class="heading">
              <h3 class="title is-size-4 has-text-secondary">
                {{ $t('views.dashboard.last-bookings') }}
              </h3>
              <a
                href="#"
                class="heading-link is-size-7">{{ $t('views.dashboard.view-more') }}</a>
            </div>
            <div class="columns is-multiline">
              <div class="column is-12  has-text-ellipsis">
                <div class="card-booking">
                  <div class="card-booking-header">
                    <div class="card-booking-status has-background-success">
                      {{ $t('views.dashboard.confirmed') }}
                    </div>
                    <div class="card-booking-ref is-size-5">
                      ABC012345
                    </div>
                    <div class="card-booking-amount">
                      <span>{{ $t('views.dashboard.booking-amount') }}:</span><strong class="is-size-5 has-text-primary">$100</strong>
                    </div>
                  </div>
                  <div class="card-booking-content">
                    <div class="attr attr-hotel">
                      <div class="attr-key">
                        {{ $t('views.dashboard.hotel') }}
                      </div>
                      <div class="attr-val">
                        <div class="has-text-primary has-text-weight-bold">
                          The Fullerton Bay Hotel
                        </div>
                        <div>Singapore, Singapore</div>
                        <div class="m-t is-size-7">
                          {{ 2|foodCode }}
                        </div>
                      </div>
                    </div>
                    <div class="attr attr-date">
                      <div class="attr-key">
                        {{ $t('views.dashboard.travel-date') }}
                      </div>
                      <div class="attr-val">
                        {{ '2019-11-1'|date('MMM D, YYYY') }} - {{ '2019-11-2'|date('MMM D, YYYY') }}
                      </div>
                    </div>
                    <div class="attr attr-name">
                      <div class="attr-key">
                        {{ $t('views.dashboard.guest-name') }}
                      </div>
                      <div class="attr-val">
                        John Doe
                      </div>
                    </div>
                    <div class="attr attr-action">
                      <div class="attr-key">
&nbsp;
                      </div>
                      <div class="attr-val">
                        <el-button class="button-secondary">
                          {{ $t('views.dashboard.details') }}
                        </el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-12  has-text-ellipsis">
                <div class="card-booking">
                  <div class="card-booking-header">
                    <div class="card-booking-status has-background-danger">
                      {{ $t('views.dashboard.failed') }}
                    </div>
                    <div class="card-booking-ref is-size-5">
                      ABC012345
                    </div>
                    <div class="card-booking-amount">
                      <span>{{ $t('views.dashboard.booking-amount') }}:</span><strong class="is-size-5 has-text-primary">$100</strong>
                    </div>
                  </div>
                  <div class="card-booking-content">
                    <div class="attr attr-hotel">
                      <div class="attr-key">
                        {{ $t('views.dashboard.hotel') }}
                      </div>
                      <div class="attr-val">
                        <div class="has-text-primary has-text-weight-bold">
                          The Fullerton Bay Hotel
                        </div>
                        <div>Singapore, Singapore</div>
                        <div class="m-t is-size-7">
                          {{ 2|foodCode }}
                        </div>
                      </div>
                    </div>
                    <div class="attr attr-date">
                      <div class="attr-key">
                        {{ $t('views.dashboard.travel-date') }}
                      </div>
                      <div class="attr-val">
                        {{ '2019-11-1'|date('MMM D, YYYY') }} - {{ '2019-11-2'|date('MMM D, YYYY') }}
                      </div>
                    </div>
                    <div class="attr attr-name">
                      <div class="attr-key">
                        {{ $t('views.dashboard.guest-name') }}
                      </div>
                      <div class="attr-val">
                        John Doe
                      </div>
                    </div>
                    <div class="attr attr-action">
                      <div class="attr-key">
&nbsp;
                      </div>
                      <div class="attr-val">
                        <el-button class="button-secondary">
                          {{ $t('views.dashboard.details') }}
                        </el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-12  has-text-ellipsis">
                <div class="card-booking">
                  <div class="card-booking-header">
                    <div class="card-booking-status has-background-success">
                      {{ $t('views.dashboard.confirmed') }}
                    </div>
                    <div class="card-booking-ref is-size-5">
                      ABC012345
                    </div>
                    <div class="card-booking-amount">
                      <span>{{ $t('views.dashboard.booking-amount') }}:</span><strong class="is-size-5 has-text-primary">$100</strong>
                    </div>
                  </div>
                  <div class="card-booking-content">
                    <div class="attr attr-hotel">
                      <div class="attr-key">
                        {{ $t('views.dashboard.hotel') }}
                      </div>
                      <div class="attr-val">
                        <div class="has-text-primary has-text-weight-bold">
                          The Fullerton Bay Hotel
                        </div>
                        <div>Singapore, Singapore</div>
                        <div class="m-t is-size-7">
                          {{ 2|foodCode }}
                        </div>
                      </div>
                    </div>
                    <div class="attr attr-date">
                      <div class="attr-key">
                        {{ $t('views.dashboard.travel-date') }}
                      </div>
                      <div class="attr-val">
                        {{ '2019-11-1'|date('MMM D, YYYY') }} - {{ '2019-11-2'|date('MMM D, YYYY') }}
                      </div>
                    </div>
                    <div class="attr attr-name">
                      <div class="attr-key">
                        {{ $t('views.dashboard.guest-name') }}
                      </div>
                      <div class="attr-val">
                        John Doe
                      </div>
                    </div>
                    <div class="attr attr-action">
                      <div class="attr-key">
&nbsp;
                      </div>
                      <div class="attr-val">
                        <el-button class="button-secondary">
                          {{ $t('views.dashboard.details') }}
                        </el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- bookmark view start-->
    <el-drawer
      :title="$t('views.dashboard.bookmarks')"
      :visible.sync="bookmarkDrawerActive"
      direction="ltr"
      size="100">
      <div class="drawer-content">
        <div class="columns is-multiline">
          <div
            v-for="i in 5"
            :key="i"
            class="column is-12  has-text-ellipsis">
            <div class="card-property">
              <i
                class="thumb"
                :style="`background-image: url(${require('../../assets/property-80X80.jpg')})`">
                <div class="icon-bookmark">
                  <Bookmark :status="true" />
                </div>
              </i>
              <span class="has-text-yellow"><i
                v-for="idx in 5"
                :key="idx"
                class="el-icon-star-on" /> </span>
              <h3 class="has-text-primary has-text-weight-bold has-text-ellipsis">
                Carlton City Hotel Singapore some text
              </h3>
              <div class="is-size-7">
                Singapore, Singapore
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- bookmark view end-->
  </div>
</template>

<script>
import SearchForm from '../share/SearchForm'
import Bookmark from '../../components/Bookmark'

export default {
  name: 'DashboardBookmarkMore',
  components: {
    Bookmark,
    SearchForm
  },
  data () {
    return {
      bookmarkDrawerActive: false
    }
  }
}
</script>

<style scoped>

</style>
